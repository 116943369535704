
import Vue from "vue";
import {mapGetters} from "vuex";

interface MenuItem {
  title: string,
  url: string,
  icon: string
}

const SUPER_USER_MENU: MenuItem[] = [
  {title: 'Home', url: '/', icon: 'mdi-home'},
  {title: 'Franchises', url: '/franchise', icon: 'mdi-office-building-cog'},
  {title: 'Franchise Facturen', url: '/franchise-invoice', icon: 'mdi-receipt'},
  {title: 'Klanten', url: '/customer', icon: 'mdi-account'},
  {title: 'Offertes', url: '/offer', icon: 'mdi-cart-plus'},
  {title: 'Bestellingen', url: '/order', icon: 'mdi-cart'},
  {title: 'Facturen', url: '/invoice', icon: 'mdi-cash-multiple'},
  {title: 'Producten', url: '/product', icon: 'mdi-silverware-fork-knife'},
  {title: 'Product opties', url: '/product-option', icon: 'mdi-silverware-variant'},
  {title: 'Postcode Management', url: '/postcode-management', icon: 'mdi-map-marker'},
  {title: 'CMS Pagina\'s', url: '/content', icon: 'mdi-content-copy'},
  {title: 'Openingstijden', url: '/settings/opening-times', icon: 'mdi-clock-outline'},
  {title: 'Rapportages', url: '/reports', icon: 'mdi-trending-up'},
  {title: 'Postcodes', url: '/postcodes', icon: 'mdi-map-legend'},
]

const USER_MENU: MenuItem[] = [
  {title: 'Home', url: '/', icon: 'mdi-home'},
  {title: 'Klanten', url: '/customer', icon: 'mdi-account'},
  {title: 'Offertes', url: '/offer', icon: 'mdi-cart-plus'},
  {title: 'Bestellingen', url: '/order', icon: 'mdi-cart'},
  {title: 'Facturen', url: '/invoice', icon: 'mdi-cash-multiple'},
  {title: 'Producten', url: '/product', icon: 'mdi-silverware-fork-knife'},
  {title: 'Route planner', url: '/route-planner', icon: 'mdi-map-marker'},
  {title: 'Openingstijden', url: '/settings/opening-times', icon: 'mdi-clock-outline'},
  {title: 'Postcodes', url: '/postcodes', icon: 'mdi-map-legend'},
];

export default Vue.extend({
  name: "MainNavigation",
  data() {
    return {
      selectedItem: 0,
    };
  },
  computed: {
    ...mapGetters(['superUser']),
    collapsedMenu: {
      get() {
        return this.$store.getters.collapsedMenu;
      },
      set(value) {
        const action = value ? 'showMenu' : 'collapseMenu';
        this.$store.commit(action);
      }
    },
    menuItems() {
      if (this.superUser) {
        return SUPER_USER_MENU;
      }

      return USER_MENU;
    },
  },
});
